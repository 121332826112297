import wx from "weixin-js-sdk";
import { signaturePack } from "@/api/wechat.service";
import { createDeviceDetector } from "next-vue-device-detector";
import Moment from "moment";
Moment.locale("zh-CN");

const device = createDeviceDetector();
const { mobile, wechat } = device;

export const time2date = (time) => {
  return Moment(time).format("YYYY-MM-DD");
};

export const time2datetime = (time) => {
  return Moment(time).format("YYYY-MM-DD HH:mm:ss");
};

export function parseUrl(url) {
  const obj = {};
  const reg = /[?&][^?&]+=[^?&]+/g;
  const arr = url.split("#")[0].match(reg);
  if (arr) {
    arr.forEach((item) => {
      const tempArr = item.substring(1).split("=");
      const key = decodeURIComponent(tempArr[0]);
      const val = decodeURIComponent(tempArr[1]);
      obj[key] = val;
    });
  }
  return obj;
}

export function getValidateScanResult(res) {
  const { errMsg, resultStr } = res;
  if (errMsg != "scanQRCode:ok" || !resultStr) {
    return false;
  }
  if (resultStr.indexOf("http://") != 0 && resultStr.indexOf("https://") != 0) {
    return false;
  }

  let domainValidate = false;
  if (resultStr.indexOf(process.env.VUE_APP_DOMAIN_WHITELIST) != -1) {
    domainValidate = true;
  }
  if (!domainValidate) {
    return false;
  }

  const { code } = parseUrl(resultStr);
  return code ? code : false;
}

export function isWechat() {
  const ua = navigator.userAgent.toLowerCase();
  return /micromessenger/.test(ua);
}

export function isAlipay() {
  const ua = navigator.userAgent.toLowerCase();
  return /alipayclient/.test(ua);
}

export const reportClientBug = async (data, location) => {
  console.log(data, location);
  // try {
  //   await reportBug({ from: "wxmp", msg: JSON.stringify(data), location });
  // } catch (error) {
  // }
};

// 通用微信页面签名, 用于隐藏页面菜单, 如果需要用别的jsapi, 比如支付, 需单独前面, 不要调用这里的通用签名
export const hideWechatOptionMenu = async () => {
  if (!mobile || !wechat) {
    return;
  }
  try {
    const url = /(Android)/i.test(navigator.userAgent)
      ? location.href.split("#")[0]
      : window.entryUrl;
    const { result } = await signaturePack(url);
    wx.config({
      appId: result.appId,
      timestamp: result.timestamp,
      nonceStr: result.nonceStr,
      signature: result.signature,
      jsApiList: ["hideOptionMenu"],
    });
    wx.ready(() => {
      wx.hideOptionMenu();
    });
    wx.error((res) => {
      reportClientBug(res, "util.hideWechatOptionMenu");
      console.log(res);
    });
  } catch (error) {
    reportClientBug(error, "util.hideWechatOptionMenu");
    const { data, statusText } = error;
    const msg = data && data.msg ? data.msg : statusText;
    console.log(msg ? msg : "前端脚本异常/页面签名失败");
  }
};

export const fuzzMobile = (phonenumber) => {
  if (!phonenumber) {
    return "";
  }
  const stringNumber = String(phonenumber);
  if (stringNumber.length == 11) {
    return stringNumber.replace(/^(\d{3})\d{6}(\d{2})$/, "$1******$2");
  } else {
    return stringNumber;
  }
};

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
};
